import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SponsorCarousel from '../../sponsorCarousel';
import HomeImageCarousel from '../../homeImageCarousel';
import DayOne from '../../../assets/images/aicon_day_one.webp';

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Home.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <div className="con-flex__container--left">
          </div>
        </div>
        <div>
          <HomeImageCarousel />
        </div>
        <p className="con-heading__m">
          {t('Pages.Home.Article.Title')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P3')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P4')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P5')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P6')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P7')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P8')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P9')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P10')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P11')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P12')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P13')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P14')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Article.P15')}
        </p>


        <p className="con-heading__m">
          {t('Pages.Home.Description.Returns')}
        </p>
        <p className="subheader con-heading__s">
          {t('Pages.Home.Description.DayOneDate')}
        </p>
        <p className="con-heading__s">
          {t('Pages.Home.Description.AINowTrack')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AINow')}
        </p>
        <p className="con-heading__s">
          {t('Pages.Home.Description.AINextTrack')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AINext')}
        </p>
        <p className="subheader con-heading__s">
          {t('Pages.Home.Description.DayTwoDate')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC3')}
        </p>

        <div className="con-home-image__container">
          <img className="con-home-image"
          src={DayOne}
          alt="First day of AI Con" />
        </div>
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Home.3rdOctober.ImageCaption')}
        </p>
        <p className="con-heading__m">
          {t('Pages.Home.3rdOctober.Title')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P3')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P4')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P5')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P6')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P7')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P8')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.3rdOctober.P9')}
        </p>
        <div>
          <SponsorCarousel />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
